import React from "react"
import RacePageOneDay2022 from "../components/racepageOneDay2022"

const Race2022 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "Strade Bianche",
      edition: 2022,
      what: "Race data",
      updated: "2022-03-04 19:05:46",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      21801: {
        teamId: "21801",
        teamUciCode: "ACT",
        teamName: "AG2R Citroën Team",
        teamNationCode: "FRA",
      },
      21803: {
        teamId: "21803",
        teamUciCode: "AST",
        teamName: "Astana Qazaqstan Team",
        teamNationCode: "KAZ",
      },
      21804: {
        teamId: "21804",
        teamUciCode: "TBV",
        teamName: "Bahrain Victorious",
        teamNationCode: "BHR",
      },
      21809: {
        teamId: "21809",
        teamUciCode: "QST",
        teamName: "Quick-Step Alpha Vinyl Team",
        teamNationCode: "BEL",
      },
      21812: {
        teamId: "21812",
        teamUciCode: "EFE",
        teamName: "EF Education-EasyPost",
        teamNationCode: "USA",
      },
      21814: {
        teamId: "21814",
        teamUciCode: "GFC",
        teamName: "Groupama-FDJ",
        teamNationCode: "FRA",
      },
      21818: {
        teamId: "21818",
        teamUciCode: "ISN",
        teamName: "Israel-Premier Tech",
        teamNationCode: "ISR",
      },
      21820: {
        teamId: "21820",
        teamUciCode: "LTS",
        teamName: "Lotto Soudal",
        teamNationCode: "BEL",
      },
      21823: {
        teamId: "21823",
        teamUciCode: "MOV",
        teamName: "Movistar Team",
        teamNationCode: "ESP",
      },
      21827: {
        teamId: "21827",
        teamUciCode: "IGD",
        teamName: "INEOS Grenadiers",
        teamNationCode: "GBR",
      },
      21830: {
        teamId: "21830",
        teamUciCode: "TJV",
        teamName: "Jumbo-Visma",
        teamNationCode: "NED",
      },
      21833: {
        teamId: "21833",
        teamUciCode: "DSM",
        teamName: "Team DSM",
        teamNationCode: "NED",
      },
      21837: {
        teamId: "21837",
        teamUciCode: "TFS",
        teamName: "Trek-Segafredo",
        teamNationCode: "USA",
      },
      21839: {
        teamId: "21839",
        teamUciCode: "UAD",
        teamName: "UAE Team Emirates",
        teamNationCode: "UAE",
      },
      21845: {
        teamId: "21845",
        teamUciCode: "AFC",
        teamName: "Alpecin-Fenix",
        teamNationCode: "BEL",
      },
      21847: {
        teamId: "21847",
        teamUciCode: "DRA",
        teamName: "Drone Hopper-Androni Giocattoli",
        teamNationCode: "ITA",
      },
      21856: {
        teamId: "21856",
        teamUciCode: "IWG",
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        teamNationCode: "BEL",
      },
      21865: {
        teamId: "21865",
        teamUciCode: "ARK",
        teamName: "Team Arkea-Samsic",
        teamNationCode: "FRA",
      },
      22245: {
        teamId: "22245",
        teamUciCode: "BOH",
        teamName: "BORA-hansgrohe",
        teamNationCode: "GER",
      },
      22480: {
        teamId: "22480",
        teamUciCode: "BEX",
        teamName: "Team BikeExchange-Jayco",
        teamNationCode: "AUS",
      },
      22591: {
        teamId: "22591",
        teamUciCode: "BCF",
        teamName: "Bardiani CSF Faizan\u00c3\u00a8",
        teamNationCode: "ITA",
      },
      23694: {
        teamId: "23694",
        teamUciCode: "EOK",
        teamName: "EOLO-Kometa Cycling Team",
        teamNationCode: "ITA",
      },
    },
    riders: {
      71217: {
        id: 71217,
        startno: 10 + 107,
        firstName: "Ben",
        lastName: "Turner",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-05-29",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      14128: {
        id: 14128,
        startno: 10 + 67,
        firstName: "Edoardo",
        lastName: "Zardini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-11-02",
        teamId: 21847,
        teamName: "Drone Hopper-Androni Giocattoli",
        stillInTheRace: "Y",
      },
      3050: {
        id: 3050,
        startno: 10 + 36,
        firstName: "Jan",
        lastName: "Tratnik",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1990-02-23",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      752: {
        id: 752,
        startno: 3,
        firstName: "Dries",
        lastName: "Devenyns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1983-07-22",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      66265: {
        id: 66265,
        startno: 212,
        firstName: "Mikkel",
        lastName: "Bjerg",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1998-11-03",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      69890: {
        id: 69890,
        startno: 10 + 96,
        firstName: "Attila",
        lastName: "Valter",
        nationCode: "HUN",
        nationName: "Hungary",
        birthDate: "1998-06-12",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      532: {
        id: 532,
        startno: 203,
        firstName: "Dario",
        lastName: "Cataldo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1985-03-17",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      48008: {
        id: 48008,
        startno: 10 + 135,
        firstName: "Sepp",
        lastName: "Kuss",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1994-09-13",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      16786: {
        id: 16786,
        startno: 10 + 131,
        firstName: "Tiesj",
        lastName: "Benoot",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-03-11",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      25726: {
        id: 25726,
        startno: 216,
        firstName: "Marc",
        lastName: "Soler",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1993-11-22",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      27275: {
        id: 27275,
        startno: 10 + 116,
        firstName: "Lorenzo",
        lastName: "Rota",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-05-23",
        teamId: 21856,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      3930: {
        id: 3930,
        startno: 207,
        firstName: "Toms",
        lastName: "Skujins",
        nationCode: "LAT",
        nationName: "Latvia",
        birthDate: "1991-06-15",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      53489: {
        id: 53489,
        startno: 10 + 146,
        firstName: "Brent",
        lastName: "Van Moer",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-01-12",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      84926: {
        id: 84926,
        startno: 10 + 104,
        firstName: "Carlos",
        lastName: "Rodriguez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "2001-02-02",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      34011: {
        id: 34011,
        startno: 10 + 3,
        firstName: "Benoit",
        lastName: "Cosnefroy",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-10-17",
        teamId: 21801,
        teamName: "AG2R Citroën Team",
        stillInTheRace: "Y",
      },
      28781: {
        id: 28781,
        startno: 31,
        firstName: "Gianni",
        lastName: "Moscon",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-04-20",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      18564: {
        id: 18564,
        startno: 10 + 132,
        firstName: "Koen",
        lastName: "Bouwman",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-12-02",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      37446: {
        id: 37446,
        startno: 10 + 54,
        firstName: "Jai",
        lastName: "Hindley",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1996-05-05",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      73467: {
        id: 73467,
        startno: 10 + 34,
        firstName: "Alejandro",
        lastName: "Osorio",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1998-05-28",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      860: {
        id: 860,
        startno: 10 + 95,
        firstName: "Thibaut",
        lastName: "Pinot",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1990-05-29",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      66439: {
        id: 66439,
        startno: 10 + 46,
        firstName: "Filippo",
        lastName: "Zana",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-03-18",
        teamId: 22591,
        teamName: "Bardiani CSF Faizan\u00c3\u00a8",
        stillInTheRace: "Y",
      },
      16992: {
        id: 16992,
        startno: 10 + 73,
        firstName: "Ruben",
        lastName: "Guerreiro",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1994-07-06",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      9894: {
        id: 9894,
        startno: 10 + 12,
        firstName: "Michael",
        lastName: "Gogl",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1993-11-04",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      37401: {
        id: 37401,
        startno: 4,
        firstName: "Mikkel",
        lastName: "Honore",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1997-01-21",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      111801: {
        id: 111801,
        startno: 192,
        firstName: "Marco",
        lastName: "Brenner",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "2002-08-27",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      77140: {
        id: 77140,
        startno: 10 + 84,
        firstName: "Alessandro",
        lastName: "Fancellu",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2000-04-24",
        teamId: 23694,
        teamName: "EOLO-Kometa Cycling Team",
        stillInTheRace: "Y",
      },
      12493: {
        id: 12493,
        startno: 10 + 93,
        firstName: "Fabian",
        lastName: "Lienhard",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1993-09-03",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      7964: {
        id: 7964,
        startno: 27,
        firstName: "Gianni",
        lastName: "Vermeersch",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-11-19",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      33263: {
        id: 33263,
        startno: 10 + 72,
        firstName: "Jonathan",
        lastName: "Caicedo",
        nationCode: "ECU",
        nationName: "Ecuador",
        birthDate: "1993-03-28",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      16963: {
        id: 16963,
        startno: 10 + 127,
        firstName: "Guy",
        lastName: "Sagiv",
        nationCode: "ISR",
        nationName: "Israel",
        birthDate: "1994-12-05",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      3929: {
        id: 3929,
        startno: 10 + 101,
        firstName: "Salvatore",
        lastName: "Puccio",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-08-31",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      150: {
        id: 150,
        startno: 11,
        firstName: "Greg",
        lastName: "Van Avermaet",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1985-05-17",
        teamId: 21801,
        teamName: "AG2R Citroën Team",
        stillInTheRace: "Y",
      },
      6409: {
        id: 6409,
        startno: 10 + 154,
        firstName: "Lluis",
        lastName: "Mas",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1989-10-15",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      40783: {
        id: 40783,
        startno: 187,
        firstName: "Matteo",
        lastName: "Sobrero",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-05-14",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      60067: {
        id: 60067,
        startno: 10 + 137,
        firstName: "Milan",
        lastName: "Vader",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-02-18",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      58639: {
        id: 58639,
        startno: 10 + 53,
        firstName: "Sergio",
        lastName: "Higuita",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1997-08-01",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      6104: {
        id: 6104,
        startno: 171,
        firstName: "Warren",
        lastName: "Barguil",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-10-28",
        teamId: 21865,
        teamName: "Team Arkea-Samsic",
        stillInTheRace: "Y",
      },
      965: {
        id: 965,
        startno: 172,
        firstName: "Anthony",
        lastName: "Delaplace",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1989-09-11",
        teamId: 21865,
        teamName: "Team Arkea-Samsic",
        stillInTheRace: "Y",
      },
      65024: {
        id: 65024,
        startno: 10 + 25,
        firstName: "Michele",
        lastName: "Gazzoli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-03-04",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      407: {
        id: 407,
        startno: 215,
        firstName: "Vegard Stake",
        lastName: "Laengen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1989-02-07",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      120: {
        id: 120,
        startno: 10 + 33,
        firstName: "Heinrich",
        lastName: "Haussler",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1984-02-25",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      99643: {
        id: 99643,
        startno: 10 + 63,
        firstName: "Didier",
        lastName: "Merchan",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1999-07-26",
        teamId: 21847,
        teamName: "Drone Hopper-Androni Giocattoli",
        stillInTheRace: "Y",
      },
      93347: {
        id: 93347,
        startno: 10 + 91,
        firstName: "Lewis",
        lastName: "Askey",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "2001-05-04",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      33285: {
        id: 33285,
        startno: 10 + 64,
        firstName: "Simone",
        lastName: "Ravanelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-07-04",
        teamId: 21847,
        teamName: "Drone Hopper-Androni Giocattoli",
        stillInTheRace: "Y",
      },
      45992: {
        id: 45992,
        startno: 211,
        firstName: "Tadej",
        lastName: "Pogacar",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1998-09-21",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      6245: {
        id: 6245,
        startno: 10 + 55,
        firstName: "Patrick",
        lastName: "Konrad",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1991-10-13",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      38481: {
        id: 38481,
        startno: 10 + 153,
        firstName: "Mathias",
        lastName: "Norsgaard",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1997-05-05",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      5306: {
        id: 5306,
        startno: 191,
        firstName: "Nikias",
        lastName: "Arndt",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1991-11-18",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      2741: {
        id: 2741,
        startno: 201,
        firstName: "Gianluca",
        lastName: "Brambilla",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-08-22",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      4317: {
        id: 4317,
        startno: 10 + 94,
        firstName: "Tobias",
        lastName: "Ludvigsson",
        nationCode: "SWE",
        nationName: "Sweden",
        birthDate: "1991-02-22",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      31066: {
        id: 31066,
        startno: 10 + 111,
        firstName: "Quinten",
        lastName: "Hermans",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-07-29",
        teamId: 21856,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      38: {
        id: 38,
        startno: 10 + 151,
        firstName: "Alejandro",
        lastName: "Valverde",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1980-04-25",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      486: {
        id: 486,
        startno: 10 + 41,
        firstName: "Giovanni",
        lastName: "Visconti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1983-01-13",
        teamId: 22591,
        teamName: "Bardiani CSF Faizan\u00c3\u00a8",
        stillInTheRace: "Y",
      },
      16660: {
        id: 16660,
        startno: 10 + 126,
        firstName: "Krists",
        lastName: "Neilands",
        nationCode: "LAT",
        nationName: "Latvia",
        birthDate: "1994-08-18",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      19274: {
        id: 19274,
        startno: 10 + 142,
        firstName: "Victor",
        lastName: "Campenaerts",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-10-28",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      37393: {
        id: 37393,
        startno: 10 + 87,
        firstName: "Diego",
        lastName: "Sevilla",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1996-03-04",
        teamId: 23694,
        teamName: "EOLO-Kometa Cycling Team",
        stillInTheRace: "Y",
      },
      22268: {
        id: 22268,
        startno: 10 + 2,
        firstName: "Lilian",
        lastName: "Calmejane",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-12-06",
        teamId: 21801,
        teamName: "AG2R Citroën Team",
        stillInTheRace: "Y",
      },
      794: {
        id: 794,
        startno: 217,
        firstName: "Diego",
        lastName: "Ulissi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-07-15",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      64658: {
        id: 64658,
        startno: 10 + 77,
        firstName: "Marijn",
        lastName: "Van Den Berg",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-07-19",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      44537: {
        id: 44537,
        startno: 10 + 15,
        firstName: "Robert",
        lastName: "Stannard",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1998-09-16",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      35077: {
        id: 35077,
        startno: 10 + 26,
        firstName: "Miguel \u00c3\u0081ngel",
        lastName: "L\u00c3\u00b3pez",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1994-02-04",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      46959: {
        id: 46959,
        startno: 177,
        firstName: "Cl\u00c3\u00a9ment",
        lastName: "Russo",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-01-20",
        teamId: 21865,
        teamName: "Team Arkea-Samsic",
        stillInTheRace: "Y",
      },
      20466: {
        id: 20466,
        startno: 10 + 44,
        firstName: "Davide",
        lastName: "Gabburo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-04-01",
        teamId: 22591,
        teamName: "Bardiani CSF Faizan\u00c3\u00a8",
        stillInTheRace: "Y",
      },
      3224: {
        id: 3224,
        startno: 10 + 51,
        firstName: "Cesare",
        lastName: "Benedetti",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1987-08-03",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      44622: {
        id: 44622,
        startno: 10 + 144,
        firstName: "Harry",
        lastName: "Sweeny",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1998-07-09",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      27121: {
        id: 27121,
        startno: 2,
        firstName: "Kasper",
        lastName: "Asgreen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1995-02-08",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      65440: {
        id: 65440,
        startno: 195,
        firstName: "Leon",
        lastName: "Heinschke",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1999-11-08",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      38004: {
        id: 38004,
        startno: 10 + 82,
        firstName: "Simone",
        lastName: "Bevilacqua",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-02-22",
        teamId: 23694,
        teamName: "EOLO-Kometa Cycling Team",
        stillInTheRace: "Y",
      },
      327: {
        id: 327,
        startno: 10 + 5,
        firstName: "Michael",
        lastName: "Sch\u00c3\u00a4r",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1986-09-29",
        teamId: 21801,
        teamName: "AG2R Citroën Team",
        stillInTheRace: "Y",
      },
      8078: {
        id: 8078,
        startno: 7,
        firstName: "Louis",
        lastName: "Vervaeke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-10-06",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      7572: {
        id: 7572,
        startno: 204,
        firstName: "Alexander",
        lastName: "Kamp",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1993-12-14",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      76245: {
        id: 76245,
        startno: 10 + 74,
        firstName: "Ben",
        lastName: "Healy",
        nationCode: "IRL",
        nationName: "Ireland",
        birthDate: "2000-09-11",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      14717: {
        id: 14717,
        startno: 10 + 23,
        firstName: "Davide",
        lastName: "Martinelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-05-31",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      70377: {
        id: 70377,
        startno: 10 + 145,
        firstName: "Maxim",
        lastName: "Van Gils",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-11-25",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      25695: {
        id: 25695,
        startno: 10 + 152,
        firstName: "Jorge",
        lastName: "Arcas",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1992-07-08",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      65515: {
        id: 65515,
        startno: 182,
        firstName: "Kevin",
        lastName: "Colleoni",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-11-11",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      10802: {
        id: 10802,
        startno: 10 + 117,
        firstName: "Taco",
        lastName: "van der Hoorn",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-12-04",
        teamId: 21856,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      6260: {
        id: 6260,
        startno: 10 + 92,
        firstName: "Antoine",
        lastName: "Duchesne",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1991-09-12",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      12474: {
        id: 12474,
        startno: 1,
        firstName: "Julian",
        lastName: "Alaphilippe",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-06-11",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      68393: {
        id: 68393,
        startno: 10 + 113,
        firstName: "Th\u00c3\u00a9o",
        lastName: "Delacroix",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-02-21",
        teamId: 21856,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      3096: {
        id: 3096,
        startno: 10 + 24,
        firstName: "Manuele",
        lastName: "Boaro",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-12-03",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      22133: {
        id: 22133,
        startno: 10 + 136,
        firstName: "Timo",
        lastName: "Roosen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-01-11",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      42185: {
        id: 42185,
        startno: 194,
        firstName: "Chris",
        lastName: "Hamilton",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1995-05-18",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      68140: {
        id: 68140,
        startno: 10 + 61,
        firstName: "Juan Diego",
        lastName: "Alba",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1997-09-11",
        teamId: 21847,
        teamName: "Drone Hopper-Androni Giocattoli",
        stillInTheRace: "Y",
      },
      3299: {
        id: 3299,
        startno: 10 + 16,
        firstName: "Scott",
        lastName: "Thwaites",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1990-02-12",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      40238: {
        id: 40238,
        startno: 10 + 62,
        firstName: "Mattia",
        lastName: "Bais",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-10-19",
        teamId: 21847,
        teamName: "Drone Hopper-Androni Giocattoli",
        stillInTheRace: "Y",
      },
      16687: {
        id: 16687,
        startno: 10 + 31,
        firstName: "Matej",
        lastName: "Mohoric",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1994-10-19",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      37439: {
        id: 37439,
        startno: 10 + 52,
        firstName: "Patrick",
        lastName: "Gamper",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1997-02-18",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      53723: {
        id: 53723,
        startno: 10 + 143,
        firstName: "Andreas",
        lastName: "Kron",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1998-06-01",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      840: {
        id: 840,
        startno: 10 + 147,
        firstName: "Roger",
        lastName: "Kluge",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1986-02-05",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      452: {
        id: 452,
        startno: 10 + 115,
        firstName: "Domenico",
        lastName: "Pozzovivo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1982-11-30",
        teamId: 21856,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      6184: {
        id: 6184,
        startno: 10 + 97,
        firstName: "Sebastien",
        lastName: "Reichenbach",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1989-05-28",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      602: {
        id: 602,
        startno: 10 + 112,
        firstName: "Jan",
        lastName: "Bakelants",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1986-02-14",
        teamId: 21856,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      41249: {
        id: 41249,
        startno: 10 + 103,
        firstName: "Jhonnatan",
        lastName: "Narvaez",
        nationCode: "ECU",
        nationName: "Ecuador",
        birthDate: "1997-03-04",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      46003: {
        id: 46003,
        startno: 10 + 14,
        firstName: "Stefano",
        lastName: "Oldani",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-01-10",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      80424: {
        id: 80424,
        startno: 10 + 43,
        firstName: "Alex",
        lastName: "Tolio",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2000-03-30",
        teamId: 22591,
        teamName: "Bardiani CSF Faizan\u00c3\u00a8",
        stillInTheRace: "Y",
      },
      16787: {
        id: 16787,
        startno: 10 + 123,
        firstName: "Jenthe",
        lastName: "Biermans",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-10-30",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      70994: {
        id: 70994,
        startno: 10 + 85,
        firstName: "Sergio",
        lastName: "Garcia",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1999-06-11",
        teamId: 23694,
        teamName: "EOLO-Kometa Cycling Team",
        stillInTheRace: "Y",
      },
      45987: {
        id: 45987,
        startno: 213,
        firstName: "Alessandro",
        lastName: "Covi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-09-28",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      38146: {
        id: 38146,
        startno: 196,
        firstName: "Joris",
        lastName: "Nieuwenhuis",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-02-11",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      624: {
        id: 624,
        startno: 214,
        firstName: "Maximiliano",
        lastName: "Richeze",
        nationCode: "ARG",
        nationName: "Argentina",
        birthDate: "1983-03-07",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      77970: {
        id: 77970,
        startno: 10 + 156,
        firstName: "Einer",
        lastName: "Rubio",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1998-02-22",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      14712: {
        id: 14712,
        startno: 193,
        firstName: "Romain",
        lastName: "Combaud",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-04-01",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      39175: {
        id: 39175,
        startno: 176,
        firstName: "Alan",
        lastName: "Riou",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1997-04-02",
        teamId: 21865,
        teamName: "Team Arkea-Samsic",
        stillInTheRace: "Y",
      },
      97996: {
        id: 97996,
        startno: 10 + 37,
        firstName: "Edoardo",
        lastName: "Zambanini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2001-04-21",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      7910: {
        id: 7910,
        startno: 10 + 76,
        firstName: "Thomas",
        lastName: "Scully",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1990-01-14",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      568: {
        id: 568,
        startno: 10 + 124,
        firstName: "Simon",
        lastName: "Clarke",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1986-07-18",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      44591: {
        id: 44591,
        startno: 173,
        firstName: "Miguel",
        lastName: "Florez",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1996-02-21",
        teamId: 21865,
        teamName: "Team Arkea-Samsic",
        stillInTheRace: "Y",
      },
      828: {
        id: 828,
        startno: 10 + 105,
        firstName: "Ben",
        lastName: "Swift",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1987-11-05",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      37351: {
        id: 37351,
        startno: 10 + 81,
        firstName: "Vincenzo",
        lastName: "Albanese",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-11-12",
        teamId: 23694,
        teamName: "EOLO-Kometa Cycling Team",
        stillInTheRace: "Y",
      },
      69502: {
        id: 69502,
        startno: 5,
        firstName: "Mauro",
        lastName: "Schmid",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1999-12-04",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      3165: {
        id: 3165,
        startno: 6,
        firstName: "Pieter",
        lastName: "Serry",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1988-11-21",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      6309: {
        id: 6309,
        startno: 10 + 71,
        firstName: "Michael",
        lastName: "Valgren",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1992-02-07",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      54530: {
        id: 54530,
        startno: 10 + 83,
        firstName: "Marton",
        lastName: "Dina",
        nationCode: "HUN",
        nationName: "Hungary",
        birthDate: "1996-04-11",
        teamId: 23694,
        teamName: "EOLO-Kometa Cycling Team",
        stillInTheRace: "Y",
      },
      20397: {
        id: 20397,
        startno: 205,
        firstName: "Jacopo",
        lastName: "Mosca",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-08-29",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      95151: {
        id: 95151,
        startno: 206,
        firstName: "Quinn",
        lastName: "Simmons",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "2001-05-08",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      6228: {
        id: 6228,
        startno: 10 + 141,
        firstName: "Tim",
        lastName: "Wellens",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-05-10",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      25690: {
        id: 25690,
        startno: 10 + 157,
        firstName: "Gonzalo",
        lastName: "Serrano",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1994-08-17",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      20378: {
        id: 20378,
        startno: 10 + 114,
        firstName: "Simone",
        lastName: "Petilli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-05-04",
        teamId: 21856,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      45602: {
        id: 45602,
        startno: 10 + 56,
        firstName: "Ide",
        lastName: "Schelling",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-02-06",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      10382: {
        id: 10382,
        startno: 202,
        firstName: "Edward",
        lastName: "Theuns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-04-30",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      6255: {
        id: 6255,
        startno: 186,
        firstName: "Tsgabu",
        lastName: "Grmay",
        nationCode: "ETH",
        nationName: "Ethiopia",
        birthDate: "1991-08-25",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      21921: {
        id: 21921,
        startno: 10 + 7,
        firstName: "Clément",
        lastName: "Venturini",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-10-16",
        teamId: 21801,
        teamName: "AG2R Citroën Team",
        stillInTheRace: "Y",
      },
      20581: {
        id: 20581,
        startno: 10 + 22,
        firstName: "Leonardo",
        lastName: "Basso",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-12-25",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      41: {
        id: 41,
        startno: 10 + 134,
        firstName: "Robert",
        lastName: "Gesink",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1986-05-31",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      2211: {
        id: 2211,
        startno: 181,
        firstName: "Michael",
        lastName: "Matthews",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1990-09-26",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      37868: {
        id: 37868,
        startno: 10 + 42,
        firstName: "Johnatan",
        lastName: "Ca\u00c3\u00b1averal",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1996-11-02",
        teamId: 22591,
        teamName: "Bardiani CSF Faizan\u00c3\u00a8",
        stillInTheRace: "Y",
      },
      57961: {
        id: 57961,
        startno: 10 + 4,
        firstName: "Clement",
        lastName: "Berthet",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1997-08-02",
        teamId: 21801,
        teamName: "AG2R Citroën Team",
        stillInTheRace: "Y",
      },
      50033: {
        id: 50033,
        startno: 10 + 47,
        firstName: "Samuele",
        lastName: "Zoccarato",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-01-09",
        teamId: 22591,
        teamName: "Bardiani CSF Faizan\u00c3\u00a8",
        stillInTheRace: "Y",
      },
      1008: {
        id: 1008,
        startno: 10 + 155,
        firstName: "Nelson",
        lastName: "Oliveira",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1989-03-06",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      55807: {
        id: 55807,
        startno: 10 + 35,
        firstName: "Hermann",
        lastName: "Pernsteiner",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1990-08-07",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      264: {
        id: 264,
        startno: 10 + 121,
        firstName: "Jakob",
        lastName: "Fuglsang",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1985-03-22",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      47841: {
        id: 47841,
        startno: 10 + 57,
        firstName: "Ben",
        lastName: "Zwiehoff",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-02-22",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      34369: {
        id: 34369,
        startno: 10 + 86,
        firstName: "Samuele",
        lastName: "Rivi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-05-11",
        teamId: 23694,
        teamName: "EOLO-Kometa Cycling Team",
        stillInTheRace: "Y",
      },
      2294: {
        id: 2294,
        startno: 10 + 125,
        firstName: "Reto",
        lastName: "Hollenstein",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1985-08-22",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      12482: {
        id: 12482,
        startno: 10 + 13,
        firstName: "Xandro",
        lastName: "Meurisse",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-01-31",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      23097: {
        id: 23097,
        startno: 10 + 102,
        firstName: "Richard",
        lastName: "Carapaz",
        nationCode: "ECU",
        nationName: "Ecuador",
        birthDate: "1993-05-29",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      22389: {
        id: 22389,
        startno: 10 + 6,
        firstName: "Andrea",
        lastName: "Vendrame",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-07-20",
        teamId: 21801,
        teamName: "AG2R Citroën Team",
        stillInTheRace: "Y",
      },
      33402: {
        id: 33402,
        startno: 10 + 45,
        firstName: "Filippo",
        lastName: "Fiorelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-11-19",
        teamId: 22591,
        teamName: "Bardiani CSF Faizan\u00c3\u00a8",
        stillInTheRace: "Y",
      },
      22679: {
        id: 22679,
        startno: 10 + 11,
        firstName: "Floris",
        lastName: "De Tier",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-01-20",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      4863: {
        id: 4863,
        startno: 10 + 32,
        firstName: "Pello",
        lastName: "Bilbao",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1990-02-25",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      39286: {
        id: 39286,
        startno: 184,
        firstName: "Alexander",
        lastName: "Konychev",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-07-25",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      7729: {
        id: 7729,
        startno: 197,
        firstName: "Martijn",
        lastName: "Tusveld",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-09-09",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      10916: {
        id: 10916,
        startno: 10 + 65,
        firstName: "Jhonatan",
        lastName: "Restrepo",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1994-11-28",
        teamId: 21847,
        teamName: "Drone Hopper-Androni Giocattoli",
        stillInTheRace: "Y",
      },
      16635: {
        id: 16635,
        startno: 174,
        firstName: "Elie",
        lastName: "Gesbert",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-07-01",
        teamId: 21865,
        teamName: "Team Arkea-Samsic",
        stillInTheRace: "Y",
      },
      1181: {
        id: 1181,
        startno: 175,
        firstName: "Romain",
        lastName: "Hardy",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1988-08-24",
        teamId: 21865,
        teamName: "Team Arkea-Samsic",
        stillInTheRace: "Y",
      },
      27277: {
        id: 27277,
        startno: 10 + 27,
        firstName: "Simone",
        lastName: "Velasco",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-12-02",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      704: {
        id: 704,
        startno: 183,
        firstName: "Sam",
        lastName: "Bewley",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1987-07-22",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      63296: {
        id: 63296,
        startno: 10 + 122,
        firstName: "Sebastian",
        lastName: "Berwick",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1999-12-15",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      116512: {
        id: 116512,
        startno: 10 + 66,
        firstName: "Brandon",
        lastName: "Rojas",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "2002-07-02",
        teamId: 21847,
        teamName: "Drone Hopper-Androni Giocattoli",
        stillInTheRace: "Y",
      },
    },
  }

  const race = "Strade Bianche"
  const raceID = 54

  return (
    <RacePageOneDay2022
      title={`${race} 2022`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default Race2022
